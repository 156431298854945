@font-face {
  font-family: Averta-Regular;
  src: url(../assets/fonts/Averta-Regular.woff);
  font-weight: normal;
}

@font-face {
  font-family: Averta-Bold;
  src: url(../assets/fonts/Averta-Bold.woff);
  font-weight: bold;
}
@font-face {
  font-family: Averta-Extra-Bold;
  src: url(../assets/fonts/Averta-Extra-Bold.woff);
  font-weight: bolder;
}
@font-face {
  font-family: Averta-Semibold;
  src: url(../assets/fonts/Averta-Semibold.woff);
  font-weight: normal;
}
@font-face {
  font-family: Averta-Light;
  src: url(../assets/fonts/Averta-Light.woff);
  font-weight: normal;
}
